var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home3"},[_c('ul',{staticClass:"flex flex-wrap reset-list"},[_vm._l((_vm.galleries),function(gallery,index){return [_c('li',{directives:[{name:"prefix",rawName:"v-prefix"}],key:gallery.id,staticClass:"#home3__list-item columns x-small-6 medium-4 x-large-3 pa-1 relative",class:{ 
                    'is-toggled': _vm.active_gallery_index === index, 
                }},[_c('button',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home3__list-item-btn reset block w-100 relative",class:{ 
                        'is-toggled': _vm.active_gallery_index === index, 
                    },attrs:{"aria-label":("Toggle " + (gallery.title) + " gallery details"),"type":"button"},on:{"click":function($event){return _vm.set_active_gallery(gallery, index)}}},[_c('aspect-ratio',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home3__list-item-inner relative overflow-hidden cursor-pointer",class:{
                            'is-toggled': _vm.active_gallery_index === index,
                        },attrs:{"ratio":1}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(gallery.image.medium),expression:"gallery.image.medium"}],staticClass:"fade-on-lazy-load object-fit-cover object-position-center object-fit-positioning",attrs:{"alt":gallery.image.alt_text}})])],1)]),_c('li',{directives:[{name:"prefix",rawName:"v-prefix"}],key:((gallery.id) + "_placeholder"),staticClass:"#home3__list-item-placeholder columns x-small-12 none maxh-0 overflow-hidden border-light-a px-1 py-2 ma-1",class:{ 
                    'is-toggled': _vm.active_placeholder_index === index 
                }},[(_vm.active_gallery)?_c('div',{staticClass:"row full-width flex flex-wrap flex-align-center"},[_c('div',{staticClass:"columns x-small-12 large-6 x-large-7 px-1"},[_c('aspect-ratio',{staticClass:"relative overflow-hidden",attrs:{"ratio":9/16}},[(_vm.active_gallery.images)?_c('div',[_c('span',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home3__list-item-placeholder-image-count sidearm-galleries-btn --primary --rounded"},[_vm._v(_vm._s(_vm.active_gallery.image_count)+" Photos")]),_vm._l((_vm.active_gallery.images.slice(0,5)),function(image){return _c('img',{directives:[{name:"prefix",rawName:"v-prefix"}],key:image.id,staticClass:"#home3__list-item-placeholder-image object-fit-cover object-position-center object-fit-positioning w-100",attrs:{"src":image.large,"alt":image.alt_text}})})],2):_vm._e()])],1),_c('div',{staticClass:"columns x-small-12 large-6 x-large-5 px-1 py-2"},[_c('router-link',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home3__list-item-placeholder-title",attrs:{"to":{ 
                                name: 'gallery', 
                                params: { 
                                    sport_slug: _vm.active_gallery.sport.slug, 
                                    gallery_slug: _vm.active_gallery.title_slug, 
                                    gallery_id: _vm.active_gallery.id
                                } 
                            }}},[_vm._v(" "+_vm._s(_vm.active_gallery.title)+" ")]),_c('div',{staticClass:"type-small mt-1"},[_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.active_gallery.sport.abbreviation))]),_vm._v(" • "),_c('span',[_vm._v(_vm._s(_vm.moment(_vm.active_gallery.date).format('MM.DD.YYYY')))])])],1)]):_vm._e()]),(index > 0 && (index+1) % 12 === 0)?_c('li',{key:("gallery_ad_" + index),staticClass:"x-small-12 columns pa-1"},[(_vm.repeated_ad_data || _vm.display_dummy_ads)?_c('dfp-ad',{attrs:{"location":_vm.repeated_ad_data.location,"name":"galleries-repeated-1"}}):_vm._e()],1):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }