var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home4"},[(_vm.galleries.length)?_c('div',[_c('div',{staticClass:"slick-slider-container",class:{ 'is-initialized': _vm.slider_initialized }},[_c('slick',{ref:"slick",attrs:{"options":_vm.slick_options}},[_vm._l((_vm.galleries),function(gallery,gallery_index){return _c('div',{directives:[{name:"prefix",rawName:"v-prefix"}],key:gallery.id,staticClass:"#home4__slider-item",on:{"mouseover":function($event){return _vm.update_hovered_slick_slide_index(gallery_index)},"mouseleave":function($event){return _vm.update_hovered_slick_slide_index(-1)}}},[_c('router-link',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home4__slider-item-link h-100 w-100 block relative overflow-hidden",attrs:{"to":{ 
                            name: 'gallery', 
                            params: { 
                                sport_slug: gallery.sport.slug, 
                                gallery_slug: gallery.title_slug.substring(0,30), 
                                gallery_id: gallery.id
                            } 
                        }}},[_c('img',{staticClass:"object-fit-cover object-position-center object-fit-positioning",attrs:{"src":gallery.image.large}}),_c('span',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home4__slider-item-hover-details"},[_vm._v("View Gallery")]),_c('span',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home4__slider-item-details flex flex-column flex-align-center flex-justify-end pa-2 text-center text-white w-100 h-100"},[_c('span',{staticClass:"type-small"},[_vm._v(_vm._s(gallery.sport.abbreviation)+" • "+_vm._s(_vm.moment(gallery.date).format('MM/DD/YYYY')))]),_c('span',{staticClass:"type-title text-bold"},[_vm._v(_vm._s(gallery.title))]),_c('span',{staticClass:"sidearm-galleries-btn --primary --small --rounded mt-1"},[_vm._v(_vm._s(gallery.image_count)+" Photos")])])])],1)}),_c('div',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home4__load-more-slide flex flex-column flex-align-center flex-justify-center h-100"},[_c('button',{staticClass:"reset",attrs:{"disabled":_vm.galleries_loading,"type":"button"},on:{"click":_vm.load_more}},[_c('icon',{staticClass:"mr-1",attrs:{"size":"1.5rem"}},[_vm._v("sf-ellipsis")]),_c('span',{staticClass:"type-base"},[_vm._v(_vm._s(_vm.galleries_loading ? 'Loading...' : 'Load More'))])],1)])],2)],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }