<!-- 
    Template Name: Cluster
    Template Setting: cluster_1
-->

<template>
    <div v-prefix class="#home3">
        <ul class="flex flex-wrap reset-list">
            <template v-for="(gallery, index) in galleries" >
                <li 
                    v-prefix 
                    :key="gallery.id" 
                    :class="{ 
                        'is-toggled': active_gallery_index === index, 
                    }" 
                    class="#home3__list-item columns x-small-6 medium-4 x-large-3 pa-1 relative"
                >
                    <button 
                        v-prefix 
                        @click="set_active_gallery(gallery, index)" 
                        :class="{ 
                            'is-toggled': active_gallery_index === index, 
                        }"
                        :aria-label="`Toggle ${gallery.title} gallery details`" 
                        type="button" 
                        class="#home3__list-item-btn reset block w-100 relative"
                    >
                        <aspect-ratio 
                            v-prefix  
                            :ratio="1" 
                            :class="{
                                'is-toggled': active_gallery_index === index,
                            }" 
                            class="#home3__list-item-inner relative overflow-hidden cursor-pointer"
                        >
                            <img v-lazy="gallery.image.medium" :alt="gallery.image.alt_text" class="fade-on-lazy-load object-fit-cover object-position-center object-fit-positioning" />
                        </aspect-ratio>
                    </button>
                </li>

                <li 
                    v-prefix 
                    :key="`${gallery.id}_placeholder`" 
                    :class="{ 
                        'is-toggled': active_placeholder_index === index 
                    }" 
                    class="#home3__list-item-placeholder columns x-small-12 none maxh-0 overflow-hidden border-light-a px-1 py-2 ma-1"
                >
                    <div class="row full-width flex flex-wrap flex-align-center" v-if="active_gallery">
                        <div class="columns x-small-12 large-6 x-large-7 px-1">
                            <aspect-ratio :ratio="9/16" class="relative overflow-hidden">
                                <div v-if="active_gallery.images">
                                    <span v-prefix class="#home3__list-item-placeholder-image-count sidearm-galleries-btn --primary --rounded">{{ active_gallery.image_count }} Photos</span>
                                    
                                    <img 
                                        v-prefix 
                                        v-for="image in active_gallery.images.slice(0,5)" 
                                        :key="image.id" 
                                        :src="image.large" 
                                        :alt="image.alt_text" 
                                        class="#home3__list-item-placeholder-image object-fit-cover object-position-center object-fit-positioning w-100" 
                                    />
                                </div>
                            </aspect-ratio>
                        </div>

                        <div class="columns x-small-12 large-6 x-large-5 px-1 py-2">
                            <router-link 
                                v-prefix
                                :to="{ 
                                    name: 'gallery', 
                                    params: { 
                                        sport_slug: active_gallery.sport.slug, 
                                        gallery_slug: active_gallery.title_slug, 
                                        gallery_id: active_gallery.id
                                    } 
                                }"
                                class="#home3__list-item-placeholder-title"
                            >
                                {{ active_gallery.title }}
                            </router-link>

                            <div class="type-small mt-1">
                                <span class="text-bold">{{ active_gallery.sport.abbreviation }}</span>
                                &bull;
                                <span>{{ moment(active_gallery.date).format('MM.DD.YYYY') }}</span>
                            </div>
                        </div>
                    </div>
                </li>

                <li v-if="index > 0 && (index+1) % 12 === 0" :key="`gallery_ad_${index}`" class="x-small-12 columns pa-1">
                    <dfp-ad v-if="repeated_ad_data || display_dummy_ads" :location="repeated_ad_data.location" name="galleries-repeated-1" />
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
    import DfpAd from '@/components/Ads/DfpAd'
    import api from '@/api'
    import { mapState } from 'vuex'

    export default {
        props: {
            galleries: {
                type: Array,
                required: true,
                default: () => ([]),
            },
        },

        components: {
            DfpAd,
        },

        data: () => ({
            active_gallery: null,

            active_gallery_index: -1,

            items_per_row: 4,

            active_placeholder_index: -1,
        }),

        computed: {
            ...mapState([
                'repeated_ad_data',
                'display_dummy_ads',
            ]),
        },

        watch: {
            active_gallery(newVal, oldVal) {
                if (newVal && (!oldVal || newVal.id !== oldVal.id)) {
                    api.get_gallery(newVal.id)
                        .then(gallery => {
                            if (this.active_gallery.id === gallery.id) {
                                this.active_gallery.images = gallery.images;
                            }
                        });
                }
            }
        },

        methods: {
            set_active_gallery(gallery, gallery_index, reset_active_gallery_info = true) {
                if (reset_active_gallery_info && this.active_gallery && this.active_gallery.id === gallery.id) {
                    this.active_gallery = null;
                    this.active_gallery_index = -1;
                    this.active_placeholder_index = -1;
                    return;
                }

                this.active_gallery = gallery;
                this.active_gallery_index = gallery_index;

                let remainder = (gallery_index + 1) % this.items_per_row;

                if (remainder === 0) {
                    this.active_placeholder_index = gallery_index;
                }
                else {
                    this.active_placeholder_index = gallery_index - remainder + this.items_per_row;
                }
            },

            set_items_per_row() {
                if (window.matchMedia('(min-width:1024px)').matches) {
                    this.items_per_row = 4;
                    this.set_active_gallery(this.active_gallery, this.active_gallery_index, false);
                    return;
                }
                else if (window.matchMedia('(min-width:540px)').matches) {
                    this.items_per_row = 3;
                    this.set_active_gallery(this.active_gallery, this.active_gallery_index, false);
                    return;
                }
                else if (window.matchMedia('(min-width:0px)').matches) {
                    this.items_per_row = 2;
                    this.set_active_gallery(this.active_gallery, this.active_gallery_index, false);
                    return;
                }
            }
        },

        mounted() {
            window.addEventListener('resize', this.set_items_per_row,);

            window.dispatchEvent(new Event('resize'));
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.set_items_per_row, false);
        }
    }
</script>

<style lang="scss" scoped>
    @keyframes fade_out_in {
        0% {
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        20% {
            opacity: 1;
        }
        30% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes grow_max_height {
        0% {
            max-height: 0px;
        }

        100% {
            max-height: 500px;
        }
    }

    .#{$prefix}__home3 {
        margin-top: $spacer*-1;

        >ul {
            margin: 0 $spacer*-1
        }

        &__list-item {            
            &.is-toggled {
                &::before, &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                }

                &::before {
                    border-left: $spacer*2 solid transparent;
                    border-right: $spacer*2 solid transparent;
                    border-bottom: $spacer solid $stroke-on-light;
                }

                &::after {
                    border-left: $spacer*2 solid transparent;
                    border-right: $spacer*2 solid transparent;
                    border-bottom: $spacer solid $color-white;
                    margin-top: 1px;
                }
            }
        }

        &__list-item-btn {
            &::before, &::after {
                content: '';
                display: block;
                left: 0;
                position: absolute;
                top: 0;
                transition: .35s;
                width: 100%;
                z-index: 1;
            }

            &::before {
                background-color: $color-primary;
                height: 100%;
                opacity: 0;
            }

            &::after {
                background-color: $color-secondary;
                height: $spacer/3;
                transform-origin: left;
                transform: scaleX(0);
            }

            &:hover, &.is-toggled {
                &::before {
                    opacity: 0.8;
                }

                &::after {
                    transform: scaleX(1);
                }
            }
        }

        &__list-item-inner {
            background-color: $stroke-on-light;
        }

        &__list-item-placeholder {
            width: calc(100% - #{$spacer*2});

            &.is-toggled {
                animation: grow_max_height .5s forwards;
                display: block !important;
            }
        }

        &__list-item-placeholder-title {
            font-size: 1.875rem;
            font-weight: bold;
            text-decoration: none;

            &:hover, &:focus {
                text-decoration: underline;
            }
        }

        &__list-item-placeholder-image {
            animation: fade_out_in 20s linear infinite;

            @for $i from 1 through 4 {
                &:nth-child(#{$i+1}) {
                    animation-delay: $i * -4s;
                }
            }
        }

        &__list-item-placeholder-image-count {
            position: absolute;
            top: $spacer*2;
            left: $spacer*2;
            z-index: 6;
        }

        @media (max-width: #{$breakpoint-medium-down}) {
            &__list-item-placeholder {
                padding: $spacer 0 0;
            }

            &__list-item-placeholder-title {
                font-size: 1.125rem;
            }
        }
    }
</style>