var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home4"},[_c('ul',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home4__list reset-list"},[_vm._l((_vm.galleries),function(gallery,gallery_index){return [_c('li',{directives:[{name:"prefix",rawName:"v-prefix"}],key:gallery.id,staticClass:"#home4__list-item border-light-a"},[_c('router-link',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home4__list-item-link flex flex-wrap large-flex-no-wrap flex-align-start large-flex-align-center pa-2",attrs:{"to":{ 
                        name: 'gallery', 
                        params: { 
                            sport_slug: gallery.sport.slug, 
                            gallery_slug: gallery.title_slug.substring(0,30), 
                            gallery_id: gallery.id
                        } 
                    }}},[_c('span',{staticClass:"x-small-12 medium-4 large-3"},[_c('aspect-ratio',{staticClass:"relative overflow-hidden",attrs:{"ratio":9/16}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(gallery.image.medium),expression:"gallery.image.medium"}],staticClass:"object-fit-cover object-position-center object-fit-positioning"})])],1),_c('span',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home4__list-item-details x-small-12 medium-8 large-9 flex-item-1 pl-2 flex flex-column flex-align-start"},[_c('span',{staticClass:"type-title text-bold flex-order-1 my-1"},[_vm._v(_vm._s(gallery.title))]),_c('span',{staticClass:"type-small text-on-light"},[_vm._v(_vm._s(gallery.sport.abbreviation)+" • "+_vm._s(_vm.moment(gallery.date).format('MM/DD/YYYY')))]),_c('span',{staticClass:"sidearm-galleries-btn --primary --rounded flex-order-2"},[_vm._v(_vm._s(gallery.image_count)+" Photos")])]),_c('span',{staticClass:"w-auto flex-item-auto text-center text-right-on-lg text-no-wrap hide-on-medium-down ml-2"},[_c('span',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home4__list-item-view-btn sidearm-galleries-btn --basic"},[_vm._v("View Gallery")])])])],1),(gallery_index > 0 && (gallery_index+1) % 12 === 0)?_c('li',{key:("gallery_ad_" + gallery_index),staticClass:"border-light-l border-light-r py-2 px-1"},[(_vm.repeated_ad_data || _vm.display_dummy_ads)?_c('dfp-ad',{attrs:{"location":_vm.repeated_ad_data.location,"name":"galleries-repeated-1"}}):_vm._e()],1):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }