<!-- 
    Template Name: Grid
    Template Setting: grid_1
-->

<template>
    <div v-prefix class="#home1">
        <ul class="flex flex-wrap reset-list">
            <template v-for="(gallery, gallery_index) in galleries">
                <li 
                    
                    :key="gallery.id" 
                    :class="{
                        'x-large-3': template_options.full_width
                    }" 
                    class="columns x-small-12 medium-6 large-4 pa-1"
                >
                    <aspect-ratio :ratio="1">
                        <router-link 
                            v-prefix
                            v-lazy:background-image="gallery.image.medium"
                            :to="{ 
                                name: 'gallery', 
                                params: { 
                                    sport_slug: gallery.sport.slug, 
                                    gallery_slug: gallery.title_slug.substring(0,30), 
                                    gallery_id: gallery.id
                                } 
                            }"
                            class="#home1__list-item-link block h-100 relative bg-size-cover bg-position-center text-white overflow-hidden"
                        >
                            <img v-lazy="gallery.image.medium" class="object-fit-cover object-position-center object-fit-positioning" />

                            <span v-prefix class="#home1__list-item-details flex flex-column flex-justify-end flex-align-center w-100 h-100 pa-2 text-center">
                                <span class="text-on-dark type-small">{{ gallery.sport.abbreviation }} &bull; {{ moment(gallery.date).format('MM/DD/YYYY') }}</span>

                                <span class="type-title text-bold">{{ gallery.title }}</span>

                                <span class="sidearm-galleries-btn --primary --small --rounded mt-1">{{ gallery.image_count }} Photos</span>
                            </span>
                        </router-link>
                    </aspect-ratio>
                </li>

                <li v-if="gallery_index > 0 && (gallery_index+1) % 12 === 0" :key="`gallery_ad_${gallery_index}`" class="x-small-12 columns pa-1">
                    <dfp-ad v-if="repeated_ad_data || display_dummy_ads" :location="repeated_ad_data.location" name="galleries-repeated-1" />
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
    import DfpAd from '@/components/Ads/DfpAd'
    import { mapState } from 'vuex'
    
    export default {
        props: {
            galleries: {
                type: Array,
                required: true,
                default: () => ([]),
            },
        },

        components: {
            DfpAd,
        },

        data: () => ({

        }),

        computed: {
            ...mapState([
                'template_options',
                'repeated_ad_data',
                'display_dummy_ads',
            ])
        },
    }
</script>

<style lang="scss" scoped>
    .#{$prefix}__home1 {
        margin: $spacer*-2 $spacer*-1 0;

        &__list-item-link {
            background-color: $stroke-on-light;
            color: $color-white;
            text-decoration: none;

            &::before {                
                content: '';
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                transition: opacity .35s;
                width: 100%;
                z-index: 1;
            }

            &:hover {
                &::before {
                    transform: translateY(100%);
                    opacity: 0;
                    transition: transform .35s, opacity 0s .35s;
                }

                img {
                    transform: scale(1.2);
                }

                .#{$prefix}__home1__list-item-details {
                    > span {
                        transform: translateY(60px);
                        opacity: 0;

                        &:nth-last-child(1) {
                            transition: .2s;
                        }

                        &:nth-last-child(2) {
                            transition: .2s .05s;
                        }

                        &:nth-last-child(3) {
                            transition: .2s .15s;
                        }
                    }
                }
            }

            &[lazy="loaded"] {
                &::before {
                    @include simple-gradient($color-black);
                }

                .#{$prefix}__home1__list-item-details {
                    opacity: 1;
                    transition: .35s;
                }
            }
        }

        img {
            transition: .35s;
        }

        &__list-item-details {
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            z-index: 2;

            > span {
                transition: .2s ease-in-out;
            }
        }
    }
</style>