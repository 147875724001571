<template>
    <div>
        <header v-prefix class="#home__header bg-white px-2 mb-1">
            <div :class="{ 'full-width': template_options.full_width }" class="row relative">
                <div class="bg-white flex flex-justify-between flex-align-center py-2">
                    <button @click="toggle_filter_popover" :class="{ 'is-toggled': filter_popover_open }" type="button" class="sidearm-galleries-btn --basic" stop-propogation>
                        <span class="mr-1">Filter</span>
                        <icon v-show="!filter_popover_open">sf-plus</icon>
                        <icon v-show="filter_popover_open">sf-close</icon>
                    </button>

                    <filter-popover v-model="filter_popover_open" />

                    <div class="text-on-light">
                        <span class="text-italic hide-on-small-down">Showing: </span>
                        <span>{{ galleries.length }} of {{ gallery_count }}</span>
                        <span class="hide-on-small-down"> galleries</span>
                    </div>
                </div>
            </div>
        </header>

        <div :class="{ 'full-width': template_options.full_width }" class="row">
            <div v-show="galleries_loading" class="mb-4 text-center">Loading...</div>

            <div v-show="!galleries_loading && !galleries.length" class="text-center pb-2">No galleries found</div>

            <component :is="template_options.home_template" :galleries="galleries" />

            <button v-if="template_supports_infinite_loading" v-show="galleries.length" @click="load_more" :disabled="galleries_loading" type="button" class="sidearm-galleries-btn --basic my-2 w-100">
                <icon size="1.5rem" class="mr-1">sf-ellipsis</icon>
                <span class="type-base">{{ galleries_loading ? 'Loading...' : 'Load More' }}</span>
            </button>
        </div>
    </div>
</template>

<script>
    import HomeTemplate1 from './Home1.vue'
    import HomeTemplate2 from './Home2.vue'
    import HomeTemplate3 from './Home3.vue'
    import HomeTemplate4 from './Home4.vue'
    import FilterPopover from '@/components/FilterPopover.vue'

    import api from '@/api'
    import { set_page_title } from '@/helpers'
    import { mapState, mapActions } from 'vuex'
    import throttle from 'lodash-es/throttle'

    export default {
        components: {
            'template_1': HomeTemplate1,
            'template_2': HomeTemplate2,
            'template_3': HomeTemplate3,
            'template_4': HomeTemplate4,
            FilterPopover,
        },

        data: () => ({
            galleries_loading: true,

            filter_popover_open: false,
        }),

        computed: {
            ...mapState([
                'template_options',
                'filters',
                'galleries',
                'gallery_count'
            ]),

            template_supports_infinite_loading() {
                const supported_templates = [
                    'template_1', 
                    'template_2',
                    'template_4',
                ];

                if (supported_templates.includes(this.template_options.home_template)) {
                    return true;
                }

                return false;
            },
        },

        watch: {
            filters: {
                handler() {
                    this.galleries_loading = true;

                    this.fetch_new_galleries()
                        .then(() => {
                            this.galleries_loading = false;
                        });
                },
                deep: true,
            },

            $route(to, from) {
                console.log(to);

                if (to.params.sport_id && to.params.sport_id !== from.params.sport_id) {
                    this.galleries_loading = true;

                    this.set_filters({
                        sport_id: to.params.sport_id,
                        season_id: 0,
                    });

                    this.fetch_new_galleries()
                        .then(() => {
                            this.galleries_loading = false;
                        });
                }

                if (!to.params.sport_id && from.params.sport_id) {
                    this.galleries_loading = true;

                    this.set_filters({
                        sport_id: 0,
                        season_id: 0,
                    });

                    this.fetch_new_galleries()
                        .then(() => {
                            this.galleries_loading = false;
                        });
                }
            }
        },

        methods: {
            ...mapActions([
                'fetch_new_galleries',
                'load_more_galleries',
                'set_filters',
            ]),

            toggle_filter_popover() {
                this.filter_popover_open = !this.filter_popover_open;
            },

            load_more() {
                if (this.galleries_loading) {
                    return;
                }

                if (document.body.offsetHeight - window.innerHeight - 100 < window.pageYOffset) {
                    this.galleries_loading = true;

                    this.load_more_galleries().then(() => {
                        this.$nextTick(() => {
                            window.requestAnimationFrame(() => {
                                setInterval(() => {
                                    this.galleries_loading = false;
                                }, 250);
                            });
                        });
                    });
                }
            },

            throttled_infinite_scroll_loader: throttle(function() {
                this.load_more();
            }, 750),
        },

        created() {
            set_page_title('Photo Galleries');

            if (!this.galleries || !this.galleries.length) {
                this.fetch_new_galleries().then(() => {
                    this.galleries_loading = false;
                });
            }
            else {
                this.galleries_loading = false;
            }

            if (this.template_supports_infinite_loading) {
                window.addEventListener('scroll', this.throttled_infinite_scroll_loader);
            }

            window.addEventListener('click', (event) => {
                var prevented = false;

                for (let i = 0; i < event.path.length; i++) {
                    let node = event.path[i];

                    if (node.nodeType === 1 && node.getAttribute('stop-propogation') !== undefined && node.getAttribute('stop-propogation') !== null) {
                        prevented = true;
                        return;
                    }
                }

                this.filter_popover_open = false;
            })
        },

        beforeDestroy() {
            window.removeEventListener('scroll', this.throttled_infinite_scroll_loader, false)
        }
    }
</script>

<style lang="scss" scoped>
    .#{$prefix}__home {
        &__header {
            margin: 0 $spacer*-2;
            position: sticky;
            top: 84px;
            z-index: 10;
        }

        @media (max-width: #{$breakpoint-medium-down}) {
            &__header {
                top: 60px;
            }
        }
    }
</style>