<!-- 
    Template Name: Pillars
    Template Setting: pillars_1
-->

<template>
    <div v-prefix class="#home4">
        <div v-if="galleries.length">
            <div :class="{ 'is-initialized': slider_initialized }" class="slick-slider-container">
                <slick ref="slick" :options="slick_options">
                    <div 
                        v-prefix 
                        @mouseover="update_hovered_slick_slide_index(gallery_index)" 
                        @mouseleave="update_hovered_slick_slide_index(-1)" 
                        v-for="(gallery, gallery_index) in galleries" 
                        :key="gallery.id"
                        class="#home4__slider-item"
                    >
                        <router-link 
                            v-prefix
                            :to="{ 
                                name: 'gallery', 
                                params: { 
                                    sport_slug: gallery.sport.slug, 
                                    gallery_slug: gallery.title_slug.substring(0,30), 
                                    gallery_id: gallery.id
                                } 
                            }"
                            class="#home4__slider-item-link h-100 w-100 block relative overflow-hidden"
                        >
                            <img :src="gallery.image.large" class="object-fit-cover object-position-center object-fit-positioning" />

                            <span v-prefix class="#home4__slider-item-hover-details">View Gallery</span>

                            <span v-prefix class="#home4__slider-item-details flex flex-column flex-align-center flex-justify-end pa-2 text-center text-white w-100 h-100">
                                <span class="type-small">{{ gallery.sport.abbreviation }} &bull; {{ moment(gallery.date).format('MM/DD/YYYY') }}</span>

                                <span class="type-title text-bold">{{ gallery.title }}</span>

                                <span class="sidearm-galleries-btn --primary --small --rounded mt-1">{{ gallery.image_count }} Photos</span>
                            </span>
                        </router-link>
                    </div>

                    <div v-prefix class="#home4__load-more-slide flex flex-column flex-align-center flex-justify-center h-100">
                        <button @click="load_more" :disabled="galleries_loading" type="button" class="reset">
                            <icon size="1.5rem" class="mr-1">sf-ellipsis</icon>
                            <span class="type-base">{{ galleries_loading ? 'Loading...' : 'Load More' }}</span>
                        </button>
                    </div>
                </slick>
            </div>
        </div>
    </div>
</template>

<script>
    import Slick from 'vue-slick'
    import { mapState, mapActions } from 'vuex'

    export default {
        props: {
            galleries: {
                type: Array,
                required: true,
                default: () => ([]),
            },
        },

        components: {
            Slick,
        },

        data: () => ({
            galleries_loading: false,

            slider_initialized: false,

            hovered_slide_index: -1,
        }),

        computed: {
            ...mapState([
                'template_options'
            ]),

            slick_options() {
                let options = {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: false,
                    responsive: [
                        { breakpoint: 1220, settings: { slidesToShow: 3, slidesToScroll: 3} },
                        { breakpoint: 920, settings: { slidesToShow: 2, slidesToScroll: 2} },
                        { breakpoint: 620, settings: { slidesToShow: 1, slidesToScroll: 1} },
                    ]
                }

                if (this.template_options && this.template_options.full_width) {
                    options.slidesToShow = 5;
                    options.slidesToScroll = 5;

                    options.responsive.unshift({ breakpoint: 1550, settings: { slidesToShow: 4, slidesToScroll: 4} })
                }

                return options;
            },
        },

        watch: {
            galleries: {
                handler(newVal, oldVal) {
                    if (!this.$refs.slick) {
                        return;
                    }

                    var current_slide = this.$refs.slick.currentSlide();
                    this.$refs.slick.destroy();

                    this.$nextTick(() => {
                        this.$refs.slick.create();
                        this.$refs.slick.goTo(current_slide, true);

                        this.$nextTick(() => {
                            this.slider_initialized = true;
                        });
                    });
                },
                deep: true,
            },
        },

        methods: {
            ...mapActions([
                'load_more_galleries',
            ]),

            load_more() {
                this.galleries_loading = true;

                this.load_more_galleries()
                    .then(() => {
                        this.$nextTick(() => {
                            this.galleries_loading = false;
                        })
                    });
            },

            update_hovered_slick_slide_index(index) {
                if (index >= 0) {
                    setTimeout(() => {
                        this.hovered_slide_index = index;
                    }, 0)
                }
                else {
                    this.hovered_slide_index = index;
                }
            }
        },

        created() {
            if (this.galleries.length) {
                this.slider_initialized = true;
            }
        },

        mounted() {
            this.$nextTick(() => {
                setTimeout(() => {
                    this.slider_initialized = true;
                }, 500);
            });
        }
    }
</script>

<style lang="scss">
    .#{$prefix}__home4 {
        .slick-slider-container {
            &:not(.is-initialized) {
                .slick-slide {
                    opacity: 0 !important;
                    transform: scale(0.75) !important;
                }
            }
        }

        .slick-slider {
            margin: 0 $spacer*-1    
        }

        .slick-track {
            display: flex;
        }

        .slick-slide {
            background-color: $stroke-on-light;
            height: calc(100vh - #{84px + $spacer*4 + $spacer*2 + $spacer*2} - 1.5rem);
            margin: 0 $spacer;
            float: none;
            opacity: 0;
            transform: scale(0.75);
            transition: 0s .35s;

            &.slick-active {
                opacity: 1;
                transform: scale(1);
                transition: .35s .2s;

                + .slick-active { transition-delay: .3s; }
                + .slick-active + .slick-active { transition-delay: .4s; }
                + .slick-active + .slick-active + .slick-active { transition-delay: .5s; }
                + .slick-active + .slick-active + .slick-active + .slick-active { transition-delay: .6s; }
            }
            
            > div, > div > div {
                height: 100%;
            }
        }

        .slick-prev, .slick-next {
            background-color: rgba($color-black, 0.6);
            border: none;
            color: $color-white;
            font-size: 0;
            height: 3rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: .35s;
            width: 3rem;
            z-index: 1;

            &::before {
                font-family: $font-icons;
                font-size: 1.5rem;
            }

            &:hover, &:focus {
                background-color: $color-primary;
                color: $text-on-primary;
            }
        }

        .slick-prev {
            border-left: none;
            left: $spacer;

            &::before {
                content: '\e009';
            }
        }

        .slick-next {
            border-right: none;
            right: $spacer;

            &::before {
                content: '\e010';
            }
        }
    }
</style>

<style lang="scss" scoped>
    .#{$prefix}__home4 {
        &__load-more-slide {
            background-color: $color-primary;
            color: $text-on-primary;
            display: flex !important;

            button {
                background-color: $color-primary;
                border: 1px solid $text-on-primary;
                color: $text-on-primary;
                font-weight: 400;
                padding: $spacer $spacer*2;
                text-align: center;
                transition: .35s;

                &:hover {
                    background-color: $color-white;
                    color: $text-on-light;
                }
            }
        }

        &__slider-item-link {
            &::before {
                @include simple-gradient($color-black);

                content: '';
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                transition: opacity .35s;
                width: 100%;
                z-index: 1;
            }

            img {
                transition: .5s;
                transform-origin: bottom center;
            }

            &:hover {
                &::before {
                    transform: translateY(100%);
                    opacity: 0;
                    transition: transform .35s, opacity 0s .35s;
                }
                
                img {
                    transform: scale(1.2);
                }
                

                .#{$prefix}__home4__slider-item-details {
                    > span {
                        transform: translateY(60px);
                        opacity: 0;

                        &:nth-last-child(1) {
                            transition: .2s;
                        }

                        &:nth-last-child(2) {
                            transition: .2s .05s;
                        }

                        &:nth-last-child(3) {
                            transition: .2s .15s;
                        }
                    }
                }

                .#{$prefix}__home4__slider-item-hover-details {
                    @include unhide();

                    left: 50%;
                    opacity: 1;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    transition: transform .35s, opacity .35s;
                }
            }
        }

        &__slider-item-details {
            bottom: 0;
            left: 0;
            position: absolute;
            z-index: 2;
        }

        &__slider-item-hover-details {
            @include hide();

            background-color: rgba($color-black, 0.6);
            border: none;
            color: $color-white;
            font-weight: 400;
            opacity: 0;
            padding: $spacer $spacer*2;
            position: absolute;
            text-align: center;
            transform: translate(-50%,-100%);
            z-index: 3;

            &:hover {
                background-color: $color-primary;
                color: $text-on-primary;

                transition: background-color .35s, color .35s !important;
            }
        }
    }
</style>