var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home1"},[_c('ul',{staticClass:"flex flex-wrap reset-list"},[_vm._l((_vm.galleries),function(gallery,gallery_index){return [_c('li',{key:gallery.id,staticClass:"columns x-small-12 medium-6 large-4 pa-1",class:{
                    'x-large-3': _vm.template_options.full_width
                }},[_c('aspect-ratio',{attrs:{"ratio":1}},[_c('router-link',{directives:[{name:"prefix",rawName:"v-prefix"},{name:"lazy",rawName:"v-lazy:background-image",value:(gallery.image.medium),expression:"gallery.image.medium",arg:"background-image"}],staticClass:"#home1__list-item-link block h-100 relative bg-size-cover bg-position-center text-white overflow-hidden",attrs:{"to":{ 
                            name: 'gallery', 
                            params: { 
                                sport_slug: gallery.sport.slug, 
                                gallery_slug: gallery.title_slug.substring(0,30), 
                                gallery_id: gallery.id
                            } 
                        }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(gallery.image.medium),expression:"gallery.image.medium"}],staticClass:"object-fit-cover object-position-center object-fit-positioning"}),_c('span',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#home1__list-item-details flex flex-column flex-justify-end flex-align-center w-100 h-100 pa-2 text-center"},[_c('span',{staticClass:"text-on-dark type-small"},[_vm._v(_vm._s(gallery.sport.abbreviation)+" • "+_vm._s(_vm.moment(gallery.date).format('MM/DD/YYYY')))]),_c('span',{staticClass:"type-title text-bold"},[_vm._v(_vm._s(gallery.title))]),_c('span',{staticClass:"sidearm-galleries-btn --primary --small --rounded mt-1"},[_vm._v(_vm._s(gallery.image_count)+" Photos")])])])],1)],1),(gallery_index > 0 && (gallery_index+1) % 12 === 0)?_c('li',{key:("gallery_ad_" + gallery_index),staticClass:"x-small-12 columns pa-1"},[(_vm.repeated_ad_data || _vm.display_dummy_ads)?_c('dfp-ad',{attrs:{"location":_vm.repeated_ad_data.location,"name":"galleries-repeated-1"}}):_vm._e()],1):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }