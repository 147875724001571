<template>
    <div v-prefix class="#home4">
        <ul v-prefix class="#home4__list reset-list">
            <template v-for="(gallery, gallery_index) in galleries">
                <li v-prefix :key="gallery.id" class="#home4__list-item border-light-a">
                    <router-link 
                        v-prefix
                        :to="{ 
                            name: 'gallery', 
                            params: { 
                                sport_slug: gallery.sport.slug, 
                                gallery_slug: gallery.title_slug.substring(0,30), 
                                gallery_id: gallery.id
                            } 
                        }"
                        class="#home4__list-item-link flex flex-wrap large-flex-no-wrap flex-align-start large-flex-align-center pa-2"
                    >
                        <span class="x-small-12 medium-4 large-3">
                            <aspect-ratio :ratio="9/16" class="relative overflow-hidden">
                                <img v-lazy="gallery.image.medium" class="object-fit-cover object-position-center object-fit-positioning" />
                            </aspect-ratio>
                        </span>

                        <span v-prefix class="#home4__list-item-details x-small-12 medium-8 large-9 flex-item-1 pl-2 flex flex-column flex-align-start">
                            <span class="type-title text-bold flex-order-1 my-1">{{ gallery.title }}</span>

                            <span class="type-small text-on-light">{{ gallery.sport.abbreviation }} &bull; {{ moment(gallery.date).format('MM/DD/YYYY') }}</span>

                            <span class="sidearm-galleries-btn --primary --rounded flex-order-2">{{ gallery.image_count }} Photos</span>
                        </span>

                        <span class="w-auto flex-item-auto text-center text-right-on-lg text-no-wrap hide-on-medium-down ml-2">
                            <span v-prefix class="#home4__list-item-view-btn sidearm-galleries-btn --basic">View Gallery</span>
                        </span>
                    </router-link>
                </li>

                <li v-if="gallery_index > 0 && (gallery_index+1) % 12 === 0" :key="`gallery_ad_${gallery_index}`" class="border-light-l border-light-r py-2 px-1">
                    <dfp-ad v-if="repeated_ad_data || display_dummy_ads" :location="repeated_ad_data.location" name="galleries-repeated-1" />
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
    import DfpAd from '@/components/Ads/DfpAd'
    import { mapState } from 'vuex'

    export default {
        props: {
            galleries: {
                type: Array,
                required: true,
                default: () => ([]),
            },
        },

        components: {
            DfpAd,
        },

        data: () => ({

        }),
        
        computed: {
            ...mapState([
                'repeated_ad_data',
                'display_dummy_ads',
            ]),
        },
    }
</script>

<style lang="scss" scoped>
    .#{$prefix}__home4 {
        &__list-item {
            margin-bottom: -1px;
        }

        &__list-item-link {
            text-decoration: none;

            &:hover {
                img {
                    transform: scale(1.1);
                }

                .#{$prefix}__home4__list-item-view-btn {
                    background-color: $color-primary;
                    border-color: $color-primary;
                    color: $text-on-primary;
                }
            }

            img {
                transition: .35s;
            }
        }

        &__list-item-view-btn {
            display: inline-block;
            width: auto;
        }

        @media (max-width: #{$breakpoint-medium-down}) {
            &__list-item-link {
                padding: $spacer !important;
            }
        }

        @media (max-width: #{$breakpoint-small-down}) {
            &__list-item-link {
                padding: $spacer;
            }

            &__list-item-details {
                padding: $spacer 0 0;
            }
        }
    }
</style>