<template>
    <div v-prefix :class="{ 'is-toggled': value, }" class="#main-header-menu-filter bg-white pa-3" stop-propogation>
        <div class="flex flex-wrap">
            <div class="x-small-12 pa-1">
                <label for="filters-sport" class="sidearm-galleries-label">Sport:</label>

                <icon-wrapper icon="sf-arrow-down-minimal" size="1.5rem">
                    <select v-model="selected_sport_id" id="filters-sport" class="sidearm-galleries-select">
                        <option v-for="sport in sports" :key="sport.id" :value="sport.id">{{ sport.title }}</option>
                    </select>
                </icon-wrapper>
            </div>

            <div class="x-small-12 pa-1">
                <label for="filters-season" class="sidearm-galleries-label">Season:</label>

                <icon-wrapper icon="sf-arrow-down-minimal" size="1.5rem">
                    <select v-model="selected_season_id" id="filters-season" class="sidearm-galleries-select">
                        <option v-for="season in seasons" :key="season.id" :value="season.id">{{ season.title }}</option>
                    </select>
                </icon-wrapper>
            </div>

            <div class="x-small-12 pa-1">
                <label for="filters-school" class="sidearm-galleries-label">Schools:</label>

                <icon-wrapper icon="sf-arrow-down-minimal" size="1.5rem">
                    <select v-model="selected_school_id" id="filters-school" class="sidearm-galleries-select">
                        <option v-for="school in schools" :key="school.id" :value="school.id">{{ school.title }}</option>
                    </select>
                </icon-wrapper>
            </div>

            <div class="x-small-12 px-1 mt-1">
                <button @click="save_filters" type="button" class="sidearm-galleries-btn --primary block w-100 type-base text-normal py-1">Apply Filters</button>
            </div>

            <div class="x-small-12 px-1 my-1 text-center">
                <button @click="clear_filters" type="button" class="reset type-small">Reset</button>
            </div>
        </div>

    </div>
</template>

<script>
    import api from '@/api'
    import { mapState, mapActions } from 'vuex'

    export default {
        props: {
            value: {
                type: Boolean,
                required: true,
                default: false,
            },
        },

        data: () => ({
            selected_sport_id: 0,

            selected_season_id: 0,

            selected_school_id: 0,
        }),

        computed: {
            ...mapState({
                _sports: state => state.sports,
                _seasons: state => state.seasons,
                _schools: state => state.schools,
            }),

            sports() {
                let sports = this._sports.slice();

                sports.unshift({
                    id: 0,
                    title: 'All Sports'
                });

                return sports;
            },

            seasons() {
                let seasons = this._seasons.slice();

                seasons.unshift({
                    id: 0,
                    title: 'All Seasons',
                });

                return seasons;
            },

            schools() {
                let schools = this._schools.slice();

                schools.unshift({
                    id: 0,
                    title: 'All Schools',
                });

                return schools;
            }
        },

        methods: {
            ...mapActions([
                'set_filters',
            ]),

            close_dialog() {
                this.$emit('input', false);
            },

            save_filters() {
                const filters = {
                    sport_id: this.selected_sport_id,
                    season_id: this.selected_season_id,
                    school_id: this.selected_school_id,
                };

                this.set_filters(filters);

                this.close_dialog();
            },

            clear_filters() {
                this.selected_sport_id = 0;
                this.selected_season_id = 0;
                this.selected_school_id = 0;

                this.save_filters();
            }
        },
    }
</script>

<style lang="scss" scoped>
    .#{$prefix}__main-header-menu-filter {
        @include hide();
        
        box-shadow: 0px 1px $spacer rgba($color-black, 0.08);
        opacity: 0;
        transition: opacity .35s;

        &.is-toggled {
            @include unhide();

            display: block;
            height: auto;
            left: 0;
            max-width: 460px;
            overflow: visible;
            opacity: 1;
            position: absolute;
            top: 100%;
            transform: translateY($spacer*-2);
            width: 100%;
            z-index: 100;
        }

        @media (max-width: #{$breakpoint-medium-down}) {
            padding: $spacer;
        }
    }
</style>